import { useState, useEffect } from "react";
import logo from "./assets/Lazem-Logo.png";
import axios from "axios";
import validator from "validator";
import { useNavigate } from "react-router-dom";
import { isAuth, setJWT, API_URL } from "./shared/services/api_auth";
import { post_data } from "./shared/services/api_data";



const LoginPage = () => {
  
  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      if (await isAuth()) {
        // redirect("/home");
        navigate("/check-guest");
      }
    };
    
    fetchData().catch(console.error);
  });

  return (
    <div>
      <LoginUI />
    </div>
  );
};

const Login = async (email: string, password: string) => {
  const response = await post_data("auth/jwt/create/", {
    username: email,
    password: password,
  });
  return response;
};


const LoginUI = () => {
  const [errorMsg, setErrorMsg] = useState<any>()
  const LoginValidation = (email: any, password: any) => {
    
    let is_valid = true;
    if (!email) {
      is_valid = false;
      setErrorMsg ("Email is required");
    } else if (!validator.isEmail(email)) {
      is_valid = false;
      setErrorMsg("Please ingress a valid email address");
    }
    if (!password) {
      setErrorMsg("password is required");
      is_valid = false;
    } else if (password.length < 6) {
      setErrorMsg("Password must be longer than 6 characters");
      is_valid = false;
    }
  
    return { is_valid: is_valid, errorMsg: errorMsg };
  };
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const emailChange = (e: any) => {
    setEmail(e.target.value);
  };
  const passwordChange = (e: any) => {
    setPassword(e.target.value);
  };
  const onSubmit = async () => {
    let validations = LoginValidation(email, password);

    if (validations.is_valid) {
      let response = await Login(email, password);
      if (response.status == 200) {
        setJWT(response.data);
        navigate("/check-guest");
      }
    }

  };
  return (
    <>
      <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="w-full max-w-md space-y-8">
          <div>
            <img className="mx-auto h-20 w-auto" src={logo} alt="Lazem" />
            <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
              Sign in to your account
            </h2>
          </div>
          <form className="my-8 mx-auto space-y-6" onSubmit={onSubmit}>
            <div className="-space-y-px rounded-md shadow-sm">
              <div>
                <label htmlFor="email-address" className="sr-only">
                  username@lazem.sa
                </label>
                <input
                  id="email-address"
                  onChange={emailChange}
                  name="email"
                  type="email"
                  required
                  className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  placeholder="username@lazem.sa"
                />
              </div>
              <div>
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <input
                  id="password"
                  onChange={passwordChange}
                  name="password"
                  type="password"
                  required
                  className="relative block w-full appearance-none rounded-none rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  placeholder="Password"
                />
              </div>
            </div>

            <div className="flex items-center justify-between"></div>

            <div>
              <button
                type="button"
                onClick={onSubmit}
                className="group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                  {/* <!-- Heroicon name: mini/lock-closed --> */}
                  <svg
                    className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 1a4.5 4.5 0 00-4.5 4.5V9H5a2 2 0 00-2 2v6a2 2 0 002 2h10a2 2 0 002-2v-6a2 2 0 00-2-2h-.5V5.5A4.5 4.5 0 0010 1zm3 8V5.5a3 3 0 10-6 0V9h6z"
                      clipRule="evenodd"
                    />
                  </svg>
                </span>
                Sign in
              </button>
            </div>
            <p className="text-red-400 text-center text-sm w-fit m-auto">{errorMsg}</p>
            
          </form>
        </div>
      </div>
    </>
  );
};

export default LoginPage;
