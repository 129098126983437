import React from "react";
import Logo from './assets/jleed-logo.png'
import './Footer.css'


const Footer = () =>{
    return(
        <footer>
            <div className="footer-wrapper">
                <img className="footer-logo" src={Logo} alt="logo" />

                <div className="links">
                    <a href="mailto:contact@lazem.sa">contact@lazem.sa</a>
                </div>

                <div className="links">
                 <a href="https://lazem.sa/">Lazem.sa</a>
                </div>

            </div>
        </footer>
    )
}

export default Footer;
