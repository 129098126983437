import Cookies from "universal-cookie";
import jwt_decode from "jwt-decode";

const cookies = new Cookies();
export const API_URL = "https://test.lazem.sa/v1/";
export const isAuth = async () => {
  const token = await getJWT();
  if (token) {
    return true;
  }
  return false;
};
export const getJWT = async () => {
  const token = cookies.get("Authorization");
  return token;
};
export const setJWT = async (response: any) => {
  console.log(response)
  console.log('response')
  let expires = new Date();
  const decoded_access_token: any = jwt_decode(response.access);
  console.log(response.access);
  console.log(decoded_access_token.exp);
  expires.setTime(expires.getTime() + decoded_access_token.exp * 1000);
  cookies.set("Authorization", response.access, { path: "/", expires });
};
