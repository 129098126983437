import React, { useEffect } from "react";
import { useState } from 'react';
import { QrReader } from 'react-qr-reader';
// import { QrScanner } from '@yudiel/react-qr-scanner';
import './Check-Guest.css';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { isAuth, setJWT, API_URL } from "./shared/services/api_auth";

const Check_Guest = () => {
        const navigate = useNavigate();
        useEffect(() => {
            const Auth = async () =>{
            
                if(await isAuth()){
                }
                else{
                    navigate("/login");
                }
            }
            Auth();
          }, []);
        const [isComplete, setIsComplete] = useState(false)
        const [checkBoxValue, setCheckBoxValue] = useState(false)
        const [isError, setIsError] = useState(false)
        const [error_msg, setErrorMsg] = useState<any>()
        const [isModify, setIsModify] = useState(false)
        const [guestInfo, setGuestInfo] = useState(
            {
                id: '',
                name: '',
                event: '',
                status: ''
            }
        )
        const header = { "Content-Type": "application/json" }
    
    
        const get_guest_info = async (_id: any) => {
    
            try {
                const response = await axios.get(API_URL +"invitation/event_attendee/" + _id, {
                    headers: header
                });
    
                let data = {
                    id: response.data.id,
                    name: response.data.full_name,
                    event: response.data.event,
                    status: response.data.status
                };
    

                setGuestInfo(data)
                setIsModify(true)
    
            } catch (error) {
                setErrorMsg(error)
                scanning_error()
            }
    
        }
    
        const page_refresh = () => {
    
            window.location.reload()
        }
    
    
        const scanning = () => {
            return (
                <div className="wrapper">
    
                    <QrReader
                        onResult={(result, error) => {
                            if (!!result) {
                                get_guest_info(result.getText())
                            }
    
                            else if (!!error) {

                            }
                        }}
                        constraints={{ facingMode: 'environment' }}
                        scanDelay={1000}
    
                    />
                </div>
            )
        }
    
        const update_statue = async () => {
            let data = { "status": "Present" }
            const response = await axios.patch(API_URL +"invitation/event_attendee/" + guestInfo.id + "/", data, {
                headers: header
            })
                .then(async (response) => {
                    setIsComplete(true)
                })
                .catch((err) => {

                    setErrorMsg(err)
                    scanning_error()
                })
                .finally(() => {
    
                });
    
        }
        const is_present = () => {
            if (guestInfo['status'] == "Present") {
                return true;
            }
            return false;
        }
    
        const complete_page = () => {
            return (
                <div className="complete">
                    <h1 className="complete-text">Complete -- اكتمل</h1>
    
                    <p className="ref-btn"
                        onClick={() => page_refresh()}
                    >
                        Scan Another
                    </p>
    
                </div>
            )
        }
    
        const modify_page = () => {
    
            return (
                <div className="modify">
                    <h1 className="text-center text-2xl">{guestInfo.name}</h1>
    
                    <h2 className="text-center px-6 py-2 rounded-lg shadow-xl bg-green-400 text-white cursor-pointer w-3/4 mx-auto my-12"
                        onClick={() => update_statue()}
                    >
                        Present
                    </h2>
    
                    <h2 className="text-center px-6 py-2 rounded-lg shadow-xl bg-gray-300 text-white cursor-pointer w-3/4 mx-auto my-12"
                        onClick={() => page_refresh()}>
                        Try Again
                    </h2>
    
                    {
                        checkBoxValue ?
                            (
                                <p className="text-center px-6 py-2 rounded-lg shadow-xl bg-green-400 text-white cursor-pointer w-fit mx-auto my-12"
                                    onClick={() => update_statue()}
                                >
                                    Submit
                                </p>
                            ) :
                            (null)
                    }
                </div>
            )
        }
    
        const scanning_error = () => {
    
            if (!isError) {
                setIsError(true)
            }
    
            return (
                <>
                    <div className="bg-red-400 w-full h-full p-6">
                        <h1 className="text-center text-5xl ">Error -- خطأ</h1>
    
                        <h2 className="text-center text-3xl">{error_msg}</h2>
    
                        <p className="text-center px-6 py-2 rounded-lg shadow-xl bg-white cursor-pointer w-fit mx-auto my-12"
                            onClick={() => page_refresh()}
                        >
                            Try Again
                        </p>
                    </div>
                </>
            )
        }
        if (!isError) {
            if (isModify) {
                if (isComplete) {
                    return complete_page()
                }
                else if(is_present()){
                    setErrorMsg("Already Present -- حاضر من قبل")
                    return scanning_error()
                }
                return modify_page()
            }
            return scanning()
        }
    
        return (
            scanning_error()
        );
    }


export default Check_Guest