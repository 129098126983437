import axios from "axios";
import { API_URL } from "./api_auth";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const header ={"Content-Type": "application/json"}
const header_jwt = {"Content-Type": "application/json", "Authorization":"JWT "+ cookies.get("Authorization")}

export const post_data = async (endpoint:string, data?:any, is_jwt?:boolean)=>{
  if(is_jwt){
    const response = await axios.post(API_URL + endpoint, data, {
      headers: header_jwt
    })
    return response
  }
  const response = await axios.post(API_URL + endpoint, data, {
    headers: header
  })
  return response;
}