import React, { useEffect, useState } from "react";
import { isAuth, setJWT, API_URL } from "./shared/services/api_auth";
import { useNavigate } from "react-router-dom";
const Home = () => {
    const navigate = useNavigate();
    useEffect(() => {
        const Auth = async () =>{
        
            if(await isAuth()){
            }
            else{
                navigate("/login");
            }
        }
        Auth();
      }, []);
      return(<>
        <div>
            redirect
            </div>  
    </>)
}

export default Home;