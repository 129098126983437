import React, { useState } from "react";
import { Routes, Route, useParams } from "react-router-dom";
import downloadjs from "downloadjs";
import html2canvas from "html2canvas";
import "./Invitation-View.css";
import QRCode from "react-qr-code";
import { QrReader } from 'react-qr-reader';
import { isAuth, setJWT, API_URL } from "./shared/services/api_auth";

const Invitation_View = () => {
  const [name, setName] = useState<any>();
  const [htmldata, setHTMLData] = useState(null);
  const [cssdata, setCSSData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [qrValue, setQrValue] = useState("");
  const [full_name, setFullName] = useState("");
  const [location, setLocation] = useState("");
  const [attendee_id, setAttendeeId] = useState("");

  const [test, setTest] = useState<any>(


  )

  const qr_html = () => {
    return (
      <>

        <div id="print-qr">
          <div className="m-6 id-qr-box">
            <h3 className="text-center text-white mb-6 qr-text">ID رمز الحضور</h3>
            <div id="qr-code">
              <QRCode bgColor="#ffffff" fgColor="#000000" value={attendee_id} className="qr-size" />
            </div>
          </div>
          <div className="m-6 profile-qr-box">
            <h3 className="text-center text-white mb-6 qr-text">الملف التعريفي </h3>
            <div id="qr-code">
              <QRCode bgColor="#ffffff" fgColor="#000000" value="https://asset.lazem.sa/jleed/profile/Lazem_Profile.pdf" className="qr-size" />
            </div>
          </div>

          <div className="m-6 location-qr-box">
            <h3 className="text-center  mb-6 qr-text">Location الموقع</h3>
            <div className="location">
              <QRCode bgColor="#ffffff" fgColor="#000000" value={location} className="qr-size" />
            </div>
          </div>
        </div>
      </>

    )
  }

  const print = async () => {
    const canvas = await html2canvas(
      document.getElementById("print") || document.body,

      { useCORS: true, allowTaint: true, scale: 5 }
    );
    const dataURL = canvas.toDataURL("image/png");
    downloadjs(dataURL, full_name + "-inv.png" || "user" + "-inv.png", "image/png");
  };
  const download_profile = async () => {
    window.open("https://asset.lazem.sa/jleed/profile/Lazem_Profile.pdf", '_blank');
  };



  const QrCode = (_data: any) => {

    setHTMLData(_data)
    let x = document.getElementById('qr-code')
    // if (x) x.innerHTML = test

  };
  async function GetInvitationData(id: string) {
    const actualData: any = await fetch(
      API_URL + "invitation/invitations/" + id
    )
      .then(async (response) => {
        let _data = await response.json();

        // getDesignData(_data["session"].certificate_design);
        get_design_data(_data.event['invitation_design']);
        setFullName(
          _data.attendee['full_name']
        );
        setAttendeeId(_data.attendee['id']);
        setLocation(_data.event['address_url'])

      })
      .catch((err) => {
        //setData(null);

        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }


  async function get_design_data(id: string) {
    const actualData: any = await fetch(
      API_URL + "invitation/invitation_design_detail/?invitation_design_id=" + id
    )
      .then(async (response) => {
        let _data = await response.json();
        let html_data = _data.find((t: any) => t.html_data);
        let css_data = _data.find((t: any) => t.css_data);
        setCSSData(css_data["css_data"]);
        QrCode(html_data["html_data"])
        // setHTMLData(html_data["html_data"]);
      })
      .catch((err) => {
        setHTMLData(null);
        setError(true);
      })
      .finally(() => {
        //setData('data');
        setLoading(false);
      });
    return htmldata;
  }


  let { id } = useParams();
  GetInvitationData(id || "");
  function unPackHtml(_data: any) {
    let html = _data;
    html = html
      .replace("{{full_name}}", full_name)
    return html;
  }
  function getHTMLData() {
    if (htmldata != null) {
      let _data = unPackHtml(htmldata);
      return _data;
    } else {
      return "تحميل...";
    }
  }
  function getCSSData() {
    if (cssdata != null || cssdata != undefined) {
      return cssdata;
    }
    return " ";
  }

  return (
    <>
      <style dangerouslySetInnerHTML={{ __html: getCSSData() }}></style>
      <div className="wrapper">
        <div className="border">
          <div id="print">
            {
            }
            <div dangerouslySetInnerHTML={{ __html: getHTMLData() }}></div>
            {qr_html()}
          </div>
        </div>
        {htmldata != null ? (
          <>
            <div className="btn" onClick={() => print()}>
              تحميل الدعوة
            </div>
            <div className="btn-profile" onClick={() => download_profile()}>
            الملف التعريفي
            </div>
          </>
        ) : null}
      </div>
    </>
  );
};

export default Invitation_View;
