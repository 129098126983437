import React, { useEffect, useState } from "react";
import "./Invited-Form.css"
import validator from "validator";
import { useParams } from "react-router-dom";
import axios from "axios";
import { post_data } from "./shared/services/api_data"
const Invited_Form = () => {

    const [invitedInfo, setInvitedInfi] = useState()
    const [contactMethod, setContactMethod] = useState('')
    const [fullName, setFullName] = useState('')
    const [methodInfo, setMethodInfo] = useState('')
    const [emailVal, setEmailVal] = useState('')
    const [phoneVal, setPhoneVal] = useState('')
    const [error_msg, setErrorMsg] = useState('')
    const { id } = useParams();
    const [isSubmited, setIsSubmited] = useState(false)
    const [pageUpdate, setPageUpdate] = useState(true)

    const header = { "Content-Type": "application/json" }


    useEffect(() => {
        
      }, [pageUpdate]);

    const clear_data = () => {
        setFullName('')
        setEmailVal('')
        setPhoneVal('')
        setErrorMsg('')
        setMethodInfo('')
        setIsSubmited(true)
    }

    const submit = async () => {

        let all_data = {
            "status": "Invite",
            "full_name": fullName,
            "phone_number": phone_data().trim(),
            "email": emailVal.trim(),
            "event": id
        }
        post_data('invitation/event_attendee/',all_data)
            .then(()=>{clear_data();setIsSubmited(true)})
            .catch((err)=>{setErrorMsg(err);setIsSubmited(false);})

        
    }
    const is_validated = () => {
        let is_valid = false;
        
        if(!fullName){
            is_valid = false;
            setIsSubmited(false)
            setErrorMsg("الرجاء ادخال اسم المدعو");
        }
        else if (!emailVal && !phone_data()) {
            is_valid = false;
            setIsSubmited(false)
            setErrorMsg("الرجاء ادخال رقم جوال او بريد الكتروني");
        } else if (!validator.isEmail(emailVal) && !validator.isMobilePhone(phone_data(), 'ar-SA')) {
            setIsSubmited(false)
            is_valid = false;
            if (!validator.isMobilePhone(phone_data(), 'ar-SA')) {
                setErrorMsg("الرجاء ادخال رقم جوال صحيح");
            }
            else {
                setErrorMsg("الرجاء ادخال بريد الكتروني صحيح");
            }


        }

        // else if (!validator.isMobilePhone(phoneVal, 'ar-SA')) {
        //     setIsSubmited(false);
        //     is_valid = false;
        // }
        else {
            submit()
        }
    }
    const set_choice = (x: string) => {
        setContactMethod(x)
    }

    const phone_data = ()=>{

        let first_char = phoneVal.charAt(0)
        if(first_char == '0') {
            let _phoneVal = phoneVal.substring(1) 
            return _phoneVal
            // setPageUpdate(!pageUpdate)
        }
        return phoneVal
    }


    const contact_choice = () => {
        if (contactMethod == "email") {
            // return(
            // )
        }

        else if (contactMethod == "phone") {
            // return(

            // )
        }

        else {
            return (
                <></>
            )
        }
    }

    return (
        <div>
            <h1 className="text-center text-3xl my-8">معلومات الضيف</h1>

            <div>
                <form className=" m-auto p-4  bg-gray-200">

                    {
                        isSubmited ? (
                            <>
                                <p className="bg-green-600 text-white w-1/2 m-auto rounded-md text-2xl p-2">تم تسجيل الضيف بنجاح</p>
                            </>
                        ) : (null)
                    }
                    <div className="input-box">
                        <h3 >اسم الضيف</h3>
                        <input required type="text" value={fullName} onChange={e => setFullName(e.target.value)} />
                        <h4 className="my-4 text-sm text-gray-400">الرجاء كتابة الإسم الكامل مع التحيه</h4>
                        <h4 className="text-gray-400 text-sm">
                            مثال: "السيد/ خالد"
                        </h4>
                        <h4 className="mb-6 text-gray-400 text-sm">
                            مثال: "سعادة الاستاذ/ محمد"
                        </h4>
                    </div>

                    {/* <div className="input-box flex flex-wrap">
                        <div className="w-fit m-auto">
                            <h3>رسالة بريد </h3>
                            <input type="radio" className="radio" onClick={()=>set_choice('email')} name="contact-method" value="email"/>

                        </div>

                        <div className="w-fit m-auto">
                            <h3>رسالة SMS</h3>
                            <input type="radio" className="radio" onClick={()=>set_choice('phone')} name="contact-method" value="phone"/>

                        </div>
                    </div> */}

                    <div className="input-box ">
                        <h3>البريد الالكتروني</h3>
                        <input type="email" value={emailVal} onChange={e => setEmailVal(e.target.value)} />

                    </div>

                    <div className="input-box">
                        <h3>رقم الجوال</h3>
                        <div className="flex m-auto justify-center mr-10">

                            <input className="w-4/5" type="tel" value={phoneVal} onChange={e => setPhoneVal(e.target.value)} placeholder='5XXXXXXXX' />
                            <p className="w-fit con-code text-sm my-auto mx-2">+996</p>
                        </div>
                    </div>

                    <p className="flex mx-auto my-8 shadow-md px-12 py-2 bg-white rounded-md cursor-pointer w-fit" onClick={() => is_validated()}>ارسال</p>

                    <p className="text-center text-red-400 m-auto">{error_msg}</p>
                </form>
            </div>
        </div>
    )
}

export default Invited_Form;