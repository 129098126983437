import React from 'react';
import logo from './logo.svg';
import { Route, Routes } from "react-router-dom";
import './App.css';
import Footer from './Pages/Footer'
import Invitation_View from './Pages/Invitation-View';
import LoginPage from './Pages/Login';
import Invited_Form from './Pages/Invited-Form';
import Check_Guest from './Pages/Check-Guest';
import HomePage from './Pages/Home-Page'

function App() {
  return (
    <>
      <Routes>
      <Route path="/" element={<HomePage />}></Route>
        <Route path="/login" element={<LoginPage />}></Route>
        <Route path="/invited-form/:id" element={<Invited_Form />}></Route>
        <Route path="/invite/:id" element={<Invitation_View />}></Route>
        <Route path="/check-guest" element={<Check_Guest />}></Route>
      </Routes>
      <Footer />
    </>
  );
}

export default App;
